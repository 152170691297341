/* fonts */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
	font-family: 'Andes';
	font-weight: 200;
	src: url('./fonts/andes/andes-extra-light.woff2') format('woff2');
}

@font-face {
	font-family: 'Andes';
	font-weight: 400;
	src: url('./fonts/andes/andes-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Andes';
	font-weight: 600;
	src: url('./fonts/andes/andes-semi-bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Andes';
	font-weight: 700;
	src: url('./fonts/andes/andes-bold.woff2') format('woff2');
}

/* base */

* {
	box-sizing: border-box;
}

body {
	margin: 0;
}

/* typography */

p {
	margin: 0 0 16px 0;
}

p:last-child {
	margin: 0;
}

/* button */

button {
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
}

/* svg */

svg {
	display: block !important;
}